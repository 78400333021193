import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output, signal, Signal } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableModule } from 'primeng/table';
import { faEllipsis, faEye, faFile } from '@fortawesome/free-solid-svg-icons';
import { OtrimFacade } from '../../services/otrim.facade';
import { IApiResponseWithData, IFileData } from '../../models';
import { FileLinksDialogComponent } from '../../shared/shared-components/file-links-modal/file-links-dialog.component';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuItem } from 'primeng/api';
import FileSaver from 'file-saver';
import { environment } from '../../../environments/environment.standalone';
import { assetUrl } from '../../../single-spa/asset-url';
import { SkeletonModule } from 'primeng/skeleton';
import { deleteFileConfirmationData } from './file-table.const';
import { ConfirmModalComponent, OToastV2Service } from 'o-suite-lib';

@Component({
  selector: 'app-shortified-files-table',
  standalone: true,
  imports: [
    TableModule,
    FontAwesomeModule,
    CommonModule,
    FileLinksDialogComponent,
    ContextMenuModule,
    SkeletonModule,
    ConfirmModalComponent,
  ],
  templateUrl: './shortified-files-table.component.html',
  styleUrl: './shortified-files-table.component.scss',
})
export class ShortifiedFilesTableComponent implements OnInit {

  private otrimFacade = inject(OtrimFacade);
  private oToastV2Service = inject(OToastV2Service);

  @Input() selectedFile!: IFileData;
  @Output() selectedFileChange = new EventEmitter<IFileData>();

  contextMenuItems: MenuItem[] = [
    {
      label: 'Download',
      command: () => {
        this.downloadFile();
      },
    },
    {
      label: 'Delete',
      command: () => {
        this.isShowConfirmModal = true;
      },
    },
  ];

  fileInfo: IFileData | null = null;
  faQrcode = faEye;
  faEllipsis = faEllipsis;
  faFile = faFile;
  deleteLoading: boolean = false;
  exportLoading: boolean = false;
  isShowConfirmModal: boolean = false;

  filesData: Signal<IFileData[]> = signal<IFileData[]>([]);

  isShowFileModal: boolean = false;

  ngOnInit(): void {
    this.filesData = this.otrimFacade.getTrimmedFilesLinks();
  }

  setSelectedItem(newItem: IFileData) {
    this.fileInfo = newItem;
    this.isShowFileModal = true;
    this.selectedFileChange.emit(newItem);
  }

  setTargetUrl(file: IFileData) {
    this.fileInfo = file;
  }

  deleteFile() {
    if (this.fileInfo && !this.deleteLoading) {
      this.deleteLoading = true;

      this.otrimFacade.deleteFile(this.fileInfo.fileId.toString()).subscribe(
        (res: IApiResponseWithData<string>) => {
          this.isShowConfirmModal = false;
          this.oToastV2Service.add({
            severity: 'success',
            summary: res?.result?.data,
            icon: environment.publicPath + '/assets/icons/toast/success.svg',
            life: environment.ToastMessageTime,
          });
          this.otrimFacade.deleteFileRecord(this.fileInfo ? this.fileInfo.fileId : 0);
        },
        (error) => {
          this.isShowConfirmModal = false;
          this.oToastV2Service.add({
            severity: 'error',
            summary: error?.error?.message ? error?.error?.message : 'Something went wrong!',
            icon: environment.publicPath + '/assets/icons/toast/error.svg',
            life: environment.ToastMessageTime,
          });
          this.deleteLoading = false;
        }
      );
    }
  }

  closeConfirmDialog() {
    this.isShowConfirmModal = false;
  }

  downloadFile() {
    if (this.fileInfo && !this.exportLoading) {
      this.exportLoading = true;
      this.otrimFacade.exportFileAsCsv(this.fileInfo.fileId.toString()).subscribe((data) => {
        this.saveFileAs(data, '.csv', this.fileInfo?.fileName ? this.fileInfo?.fileName : 'links_file');
        this.oToastV2Service.add({
          severity: 'success',
          summary: 'File Downloaded Successfully',
          icon: environment.publicPath + '/assets/icons/toast/success.svg',
          life: environment.ToastMessageTime,
        });
        this.exportLoading = false;
      });
    }
  }

  saveFileAs(response: any, type: any, fileName: any) {
    const blob = new Blob([response], { type: 'text/plain' });
    const file = fileName + type;
    FileSaver.saveAs(blob, file);
  }

  protected readonly assetUrl = assetUrl;
  protected isLinksDataLoading = this.otrimFacade.isLinksDataLoadingSignal;
  protected readonly deleteFileConfirmationData = deleteFileConfirmationData;
  protected readonly environment = environment;
}
