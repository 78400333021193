import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TableModule } from 'primeng/table';
import { environment } from '../../../../environments/environment';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import {
  IApiResponseWithData,
  IFileData,
  IFileLinksPayload,
  IFileStatData,
  IFileTrimmedLinksData,
} from '../../../models';
import { OtrimFacade } from '../../../services/otrim.facade';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import FileSaver from 'file-saver';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, mergeMap, Observable, switchMap } from 'rxjs';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-file-links-dialog',
  standalone: true,
  imports: [
    DialogModule,
    FaIconComponent,
    TableModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    ToastModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    TooltipModule,
  ],
  providers: [MessageService],
  templateUrl: './file-links-dialog.component.html',
  styleUrl: './file-links-dialog.component.scss',
})
export class FileLinksDialogComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input('fileInfo') set setFileInfo(value: IFileData | IFileStatData | null) {
    this.fileInfo = value;
    if (value) {
      this.getData();
    }
  }

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  @Output() fileInfoChange: EventEmitter<any> = new EventEmitter();

  fileInfo: IFileData | IFileStatData | null = null;
  fileTrimmedLinksData: IFileTrimmedLinksData[] = [];
  exportLoading: boolean = false;
  deleteLoading: boolean = false;
  searchControl = new FormControl('');
  private otrimFacade = inject(OtrimFacade);
  private messageService = inject(MessageService);

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(700),
        distinctUntilChanged(),
        switchMap(
          (query): Observable<IApiResponseWithData<IFileTrimmedLinksData[]>> =>
            this.getFileLinks(this.fileInfo ? this.fileInfo?.fileId : 0, query || '')
        )
      )
      .subscribe({
        next: (results: IApiResponseWithData<IFileTrimmedLinksData[]>) => {
          this.fileTrimmedLinksData = results.result.data;
        },
        error: (err) => console.error('Error:', err),
      });
  }

  closeDialog() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    this.fileInfo = null;
    this.fileInfoChange.emit(this.fileInfo);
  }

  getData() {
    if (this.fileInfo) {
      this.getFileLinks(this.fileInfo?.fileId).subscribe((res: IApiResponseWithData<IFileTrimmedLinksData[]>) => {
        this.fileTrimmedLinksData = res.result.data;
      });
    }
  }

  getFileLinks(fileId: number, searchKey: string = '') {
    let params: IFileLinksPayload = {
      pageSize: 50,
      pageIndex: 0,
      searchKey: searchKey,
      orderBy: 'asc',
      fileId: fileId,

      fromDate: '',
      isCustom: true,
      sortBy: '',
      toDate: '',
      userId: 0,
    };

    return this.otrimFacade.getDateRange().pipe(
      mergeMap((res: any) => {
        return this.otrimFacade.getFileLinks({ ...params, ...res });
      })
    );
  }

  deleteFile() {
    if (this.fileInfo && !this.deleteLoading) {
      this.deleteLoading = true;

      this.otrimFacade.deleteFile(this.fileInfo.fileId.toString()).subscribe(
        (res: IApiResponseWithData<string>) => {
          this.messageService.add({
            severity: 'success',
            detail: res?.result?.data,
            key: 'br',
            life: environment.ToastMessageTime,
          });
          this.closeDialog();
          this.deleteLoading = false;
          this.otrimFacade.deleteFileRecord(this.fileInfo ? this.fileInfo.fileId : 0);
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            detail: error.message,
            key: 'br',
            life: environment.ToastMessageTime,
          });
          this.deleteLoading = false;
        }
      );
    }
  }

  downloadFile() {
    if (this.fileInfo && !this.exportLoading) {
      this.exportLoading = true;
      this.otrimFacade.exportFileAsCsv(this.fileInfo.fileId.toString()).subscribe((data) => {
        this.saveFileAs(data, '.csv', this.fileInfo?.fileName ? this.fileInfo?.fileName : 'links_file');
        this.messageService.add({
          severity: 'success',
          detail: 'File Downloaded Successfully',
          key: 'br',
          life: environment.ToastMessageTime,
        });
        this.exportLoading = false;
      });
    }
  }

  saveFileAs(response: any, type: any, fileName: any) {
    const blob = new Blob([response], { type: 'text/plain' });
    const file = fileName + type;
    FileSaver.saveAs(blob, file);
  }

  // todo: we need full link object from BE

  // handleShowAnalytics(url: ITrimmedLinksData) {
  // this.setTargetUrl(url);
  // this.router.navigate(['/trim/link', url?.trimUrlId]);
  // }

  // setTargetUrl(url: ITrimmedLinksData) {
  //   sessionStorage.setItem('targetShortEndUrl', url?.shortEndUrl);
  // }

  protected readonly environment = environment;
  protected readonly faEllipsis = faEllipsis;
}
