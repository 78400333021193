<o-confirm-modal
  [visible]="isShowConfirmModal"
  [data]="deleteUrlConfirmationData"
  (acceptConfirmation)="deleteFile()"
  (rejectConfirmation)="closeConfirmDialog()"
></o-confirm-modal>

<p-toast position="bottom-right" key="br" />

@if (trimmedLinks.length) {
  <div class="url-table__container">
    <p-table [value]="trimmedLinks" styleClass=" p-datatable-sm w-full">
      <ng-template pTemplate="header">
        <tr>
          <th class="tabel-header-text">URLs</th>
          <th class="tabel-header-text">Alias</th>
          <th class="tabel-header-text">Clicks</th>
          <th class="tabel-header-text">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-url>
        <tr>
          <td class="col-6">
            <div class="flex flex-column">
              <p class="my-0">
                <a class="text-primary font-semibold text-lg my-0 no-underline">
                  {{ environment.baseLink + url.shortEndUrl }}
                </a>
              </p>
              <p
                [autoHide]="false"
                [pTooltip]="url.destinationUrl"
                tooltipPosition="top"
                class="my-0 text-base mt-2 white-space-nowrap overflow-hidden text-overflow-ellipsis text-ellipsis table-custom-text"
              >
                {{ url.destinationUrl }}
              </p>
            </div>
          </td>
          <td class="col-2">{{ url.aliasName }}</td>
          <td class="col-2">{{ url.clickCount }}</td>
          <td class="col-2">
            <div class="flex gap-4 icon-colors">
              <img src="../../../assets/icons/analytic.svg" (click)="handleShowAnalytics(url)" />
              <img src="../../../assets/icons/share.svg" (click)="handleShare()" />
              <img src="../../../assets/icons/qr-code.svg" (click)="handleShowQRCode()" />
              <div #contextTarget class="cursor-pointer" (click)="setTargetUrl(url)">
                <fa-icon [icon]="faEllipsis" [size]="'lg'" />
              </div>
              <p-contextMenu
                [target]="contextTarget"
                [model]="contextMenuItems"
                [appendTo]="'body'"
                triggerEvent="click"
              />
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
} @else {
  <div class="w-full h-28rem flex flex-column gap-4 justify-content-center align-items-center url-table--empty-state">
    <img src="../../../assets/icons/empty-table.svg" />
    <h2 class="my-0">No Short URLs</h2>
    <p class="font-medium text-center">
      Here, you can view all the trimmed URLs created, along with options
      <br />
      to manage and track each link by the number of clicks received.
    </p>
  </div>
}
