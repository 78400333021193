import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableModule } from 'primeng/table';
import { faEllipsis, faEye, faFile } from '@fortawesome/free-solid-svg-icons';
import { OtrimFacade } from '../../services/otrim.facade';
import { IApiResponseWithData, IFileData } from '../../models';
import { FileLinksDialogComponent } from '../../shared/shared-components/file-links-modal/file-links-dialog.component';
import { mergeMap, Observable } from 'rxjs';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuItem, MessageService } from 'primeng/api';
import FileSaver from 'file-saver';
import { environment } from '../../../environments/environment.standalone';

@Component({
  selector: 'app-file-table',
  standalone: true,
  imports: [TableModule, FontAwesomeModule, CommonModule, FileLinksDialogComponent, ContextMenuModule],
  providers: [MessageService],
  templateUrl: './file-table.component.html',
  styleUrl: './file-table.component.scss',
})
export class FileTableComponent implements OnInit {
  @Input() selectedFile!: IFileData;

  @Output() selectedFileChange = new EventEmitter<IFileData>();

  contextMenuItems!: MenuItem[];
  fileInfo: IFileData | null = null;
  faQrcode = faEye;
  faEllipsis = faEllipsis;
  faFile = faFile;
  deleteLoading: boolean = false;
  exportLoading: boolean = false;

  private otrimFacade = inject(OtrimFacade);
  private messageService = inject(MessageService);
  filesData: IFileData[] = [];
  isShowFileModal: boolean = false;

  setSelectedItem(newItem: IFileData) {
    this.fileInfo = newItem;
    this.isShowFileModal = true;
    this.selectedFileChange.emit(newItem);
  }

  setTargetUrl(file: IFileData) {
    this.fileInfo = file;
  }

  ngOnInit() {
    const params = {
      pageSize: 50,
      pageIndex: 0,
      searchKey: '',
      orderBy: 'asc',
    };

    this.otrimFacade
      .getAllFilesData(params)
      .pipe(
        mergeMap((res: IApiResponseWithData<IFileData[]>): Observable<IFileData[] | null> => {
          this.otrimFacade.setFilesRecords(res.result.data);
          return this.otrimFacade.getFilesRecords();
        })
      )
      .subscribe((res: any) => {
        this.filesData = res;
      });

    this.contextMenuItems = [
      {
        label: 'Download',
        command: () => {
          this.downloadFile();
        },
      },
      {
        label: 'Delete',
        command: () => {
          this.deleteFile();
        },
      },
    ];
  }

  deleteFile() {
    if (this.fileInfo && !this.deleteLoading) {
      this.deleteLoading = true;

      this.otrimFacade.deleteFile(this.fileInfo.fileId.toString()).subscribe(
        (res: IApiResponseWithData<string>) => {
          this.messageService.add({
            severity: 'success',
            detail: res?.result?.data,
            key: 'br',
            life: environment.ToastMessageTime,
          });
          this.otrimFacade.deleteFileRecord(this.fileInfo ? this.fileInfo.fileId : 0);
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            detail: error.message,
            key: 'br',
            life: environment.ToastMessageTime,
          });
          this.deleteLoading = false;
        }
      );
    }
  }

  downloadFile() {
    if (this.fileInfo && !this.exportLoading) {
      this.exportLoading = true;
      this.otrimFacade.exportFileAsCsv(this.fileInfo.fileId.toString()).subscribe((data) => {
        this.saveFileAs(data, '.csv', this.fileInfo?.fileName ? this.fileInfo?.fileName : 'links_file');
        this.messageService.add({
          severity: 'success',
          detail: 'File Downloaded Successfully',
          key: 'br',
          life: environment.ToastMessageTime,
        });
        this.exportLoading = false;
      });
    }
  }

  saveFileAs(response: any, type: any, fileName: any) {
    const blob = new Blob([response], { type: 'text/plain' });
    const file = fileName + type;
    FileSaver.saveAs(blob, file);
  }
}
