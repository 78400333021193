import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DataViewModule } from 'primeng/dataview';
import { environment } from '../../../../environments/environment.dev';
import { ICityListItemModel } from '../../../models';

@Component({
  selector: 'app-city-list-view',
  standalone: true,
  imports: [DataViewModule, CommonModule],
  templateUrl: './city-list-view.component.html',
  styleUrl: './city-list-view.component.scss',
})
export class CityListViewComponent {
  @Input() cities!: ICityListItemModel[];
  countryFlagUrl = environment.COUNTRY_SVG_URL;
}
