import { Component, inject, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { CommonModule } from '@angular/common';
import { ShortUrlComponent } from '../../components/short-url/short-url-component';
import { AnalyticsCardComponent } from '../../shared/shared-components/analytics-card/analytics-card-component';
import { TableOptionsComponent } from '../../components/table-options/table-options.component';
import { UrlTableComponent } from '../../components/url-table/url-table.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { FileTableComponent } from '../../components/file-table/file-table.component';
import { StaticsComponent } from '../../components/Statics/statics.component';
import { IAnalyticCard, IDashboardPayload, ITrimDropdownOption } from '../../models';
import { ANALYTIC_CARDS_DATA, TRIM_DROPDOWN_OPTIONS } from '../../shared/constants';
import { OtrimFacade } from '../../services/otrim.facade';
import { mergeMap } from 'rxjs';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    FontAwesomeModule,
    TableModule,
    ButtonModule,
    DividerModule,
    CommonModule,
    ShortUrlComponent,
    AnalyticsCardComponent,
    TableOptionsComponent,
    UrlTableComponent,
    BreadcrumbModule,
    StaticsComponent,
    FileTableComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  showList = true;
  totalLinks: number = 0;
  private otrimFacade = inject(OtrimFacade);
  analyticsCards: IAnalyticCard[] = ANALYTIC_CARDS_DATA;
  selectedOption: ITrimDropdownOption = TRIM_DROPDOWN_OPTIONS.ALL_LINK;
  selecteOptions = TRIM_DROPDOWN_OPTIONS;

  onTabChange(show: boolean) {
    this.showList = show;
  }

  ngOnInit() {
    const params = {
      type: 'trim',
      fromDate: '',
      toDate: '',
      campaignId: '',
    };

    this.getDashboardData(params);
  }

  getDashboardData(params: IDashboardPayload) {
    this.otrimFacade
      .getDateRange()
      .pipe(
        mergeMap((res: any) => {
          return this.otrimFacade.getDashboardData({ ...params, ...res });
        })
      )
      .subscribe((res: any) => {
        this.analyticsCards = this.analyticsCards.map((card) => ({
          ...card,
          data: res.result[card.key],
        }));
        this.totalLinks = res?.result?.totalLinks;
        this.otrimFacade.setDashboardStatistics(res?.result);
      });
  }
}
