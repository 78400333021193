import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ITrimmedLinksData, IDashboard, IFileData, IRangeDatePayloads } from '../models';

// ToDo: we need to have multiple services each service is responsible for one state instead of centralized service state
@Injectable({
  providedIn: 'root',
})
export class OtrimState {
  private trimmedSingleLinksSignal = signal<ITrimmedLinksData[]>([]);
  trimmedSingleLinks = this.trimmedSingleLinksSignal.asReadonly();

  private trimmedFilesLinksSignal = signal<IFileData[]>([]);
  trimmedFilesLinks = this.trimmedFilesLinksSignal.asReadonly();

  private dashboardStatisticsSignal = signal<IDashboard | null>(null);
  dashboardStatistics = this.dashboardStatisticsSignal.asReadonly();

  linkToEdit = signal<ITrimmedLinksData | null>(null);

  dateRange = new BehaviorSubject<IRangeDatePayloads>({ fromDate: '', toDate: '' });

  setDashboardStatistics(stat: IDashboard) {
    this.dashboardStatisticsSignal.set({ ...stat });
  }

  deleteFileRecord(fileId: number): void {
    const updatedRecords = this.trimmedFilesLinks()?.filter((record) => record.fileId !== fileId) || [];
    this.trimmedFilesLinksSignal.set([...updatedRecords]);
  }

  setDateRange(data: IRangeDatePayloads) {
    this.dateRange.next(data);
  }

  getDateRange(): Observable<IRangeDatePayloads> {
    return this.dateRange.asObservable();
  }

  setTrimmedLinks(data: ITrimmedLinksData[]) {
    this.trimmedSingleLinksSignal.set([...data]);
  }

  updateTrimmedLink(trimUrlId: number, updatedData: Partial<ITrimmedLinksData>): void {
    const newData = this.trimmedSingleLinksSignal().map((link) =>
      link.trimUrlId === trimUrlId ? { ...link, ...updatedData } : link
    );

    this.trimmedSingleLinksSignal.set([...newData]);
  }

  setTrimmedFilesLinks(data: IFileData[]) {
    this.trimmedFilesLinksSignal.set([...data]);
  }

  deleteTrimmedLinkById(trimUrlId: number): void {
    const updatedRecords = this.trimmedSingleLinksSignal()?.filter((record) => record.trimUrlId !== trimUrlId) || [];
    this.trimmedSingleLinksSignal.set([...updatedRecords]);
  }

  setLinkToEdit(linkData: ITrimmedLinksData | null) {
    this.linkToEdit.set(linkData);
  }

  addTrimmedLink(trimmedLink: ITrimmedLinksData) {
    const currentLinks = this.trimmedSingleLinks();
    this.trimmedSingleLinksSignal.set([trimmedLink, ...currentLinks]);
  }
}
