export const environment = {
  publicPath: 'https://o-suite-trim-dev.onfusiondemo.cc',
  COUNTRY_SVG_URL: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/',
  production: false,
  apiUrl: 'https://oes-api.onpassive.com/',
  DEFAULT_KEY: '*$_0x8d91f7i*[^',
  otrimApiUrl: 'https://oes-api-dev.onfusiondemo.cc/otrim/',
  baseLink: 'https://oes-api-dev.onfusiondemo.cc/otrim/',
  ToastMessageTime: 1500,
  bearerToken:
    'eyJhbGciOiJIUzUxMiJ9.eyJmbmFtZSI6InNhYmFoIiwic3ViIjoic2FiYWg5OUBvbmZ1c2lvbnQubmV0IiwibG5hbWUiOiJ0ciIsInByb2R1Y3QiOiJvYnMiLCJzdWJzY3JpYmVkX3Byb2R1Y3RzIjpbXSwiaXNzIjoiaHR0cHM6Ly9vZXMtYXBpLWRldi5vbmZ1c2lvbmRlbW8uY2Mvb2VzdXNlci9sb2dpbiIsInNjb3BlcyI6W3siYXV0aG9yaXR5IjoidXNlciJ9XSwiZXhwIjoxNzMzNDI1NTA3LCJjdXN0YWNjaWQiOjM2MTk4NDcsImlhdCI6MTczMzM4MjMwNywianRpIjoiZDUxY2M2MzMtZTI0YS00ZWExLWExMmUtNTcyZjA0M2ZjMjExIiwiY2lkIjozODgzNjE1fQ.ijc8CyTVROhsfUeGqT0Lelkr5h809uIUZYgWY9I7sFLMoUHOOnc3dp_KPtyrJoZDZgunhlU5Upwk3WtV61w5sw',
};
