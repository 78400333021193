<p-toast position="bottom-right" key="br" />

<p-dialog [modal]="true" (onHide)="closeDialog()" [(visible)]="visible" [draggable]="false" [dismissableMask]="true">
  <div class="flex flex-column align-items-center justify-content-center bulk-dialog">
    <p class="mb-2 mt-0 text-center text-3xl font-bold w-full bulk-dialog__title">Bulk file {{ fileInfo?.fileName }}</p>

    <div class="bulk-dialog__action-bar w-full flex flex-row align-items-center justify-content-between mt-5 mb-4">
      <p-iconField iconPosition="left">
        <p-inputIcon styleClass="pi pi-search text-bluegray-200" />
        <input type="text" pInputText placeholder="Search URLs" [formControl]="searchControl" />
      </p-iconField>

      <div class="flex align-items-center">
        <span
          (click)="deleteFile()"
          class="bulk-dialog__action-bar-delete-btn border-round-2xl cursor-pointer border-solid border-1 w-3rem h-3rem flex align-items-center justify-content-center"
        >
          @if (!deleteLoading) {
            <img src="assets/icons/delete-icon.svg" width="16" height="19" alt="delete" />
          } @else {
            <p-progressSpinner ariaLabel="loading" styleClass="export-loading" />
          }
        </span>
        <span
          (click)="downloadFile()"
          class="bulk-dialog__action-bar-download-btn border-round-2xl cursor-pointer ml-2 border-solid border-1 w-3rem h-3rem flex align-items-center justify-content-center"
        >
          @if (!exportLoading) {
            <img src="assets/icons/download-icon.svg" width="19" height="19" alt="download" />
          } @else {
            <p-progressSpinner ariaLabel="loading" styleClass="export-loading" />
          }
        </span>
      </div>
    </div>

    <p-table [value]="fileTrimmedLinksData" styleClass="p-datatable-gridlines p-datatable-sm" class="w-full">
      <ng-template pTemplate="header">
        <tr>
          <th>URLs</th>
          <th>Alias</th>
          <th>Clicks</th>
          <th>Actions</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-url>
        <tr>
          <td class="col-6">
            <div class="flex flex-column">
              <p class="text-primary font-semibold text-lg my-0">
                {{ environment.baseLink + url.shortEndUrl }}
              </p>
              <p
                [autoHide]="false"
                [pTooltip]="url.destinationUrl"
                tooltipPosition="top"
                class="my-0 text-base mt-2 white-space-nowrap overflow-hidden text-overflow-ellipsis text-ellipsis table-custom-text"
              >
                {{ url.destinationUrl }}
              </p>
            </div>
          </td>
          <td class="col-2">{{ url.aliasName }}</td>
          <td class="col-2">{{ url.clickCount }}</td>
          <td class="col-2">
            <div class="flex gap-4 icon-colors">
              <img src="../../../assets/icons/analytic.svg" />
              <img src="../../../assets/icons/share.svg" />
              <img src="../../../assets/icons/qr-code.svg" />
              <div #contextTarget class="cursor-pointer">
                <fa-icon [icon]="faEllipsis" [size]="'lg'" />
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>
