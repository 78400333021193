import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { Button } from 'primeng/button';
import { NgForOf } from '@angular/common';
import { CSocialMediaData } from './social-media.const';

@Component({
  selector: 'app-share-dialog',
  standalone: true,
  imports: [DialogModule, Button, NgForOf],
  templateUrl: './share-dialog.component.html',
  styleUrl: './share-dialog.component.scss',
})
export class ShareDialogComponent {
  @Input('visible') visible: boolean = false;
  oTrimLink = input.required<string>();
  @Output() fileUploaded = new EventEmitter<File>();
  copySuccessMessage: string = 'Copy link';
  @Output() visibleChange = new EventEmitter<boolean>();

  closeDialog() {
    this.visible = false;
  }

  copyToClipboard(text: string): void {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.copySuccessMessage = 'Copied to clipboard!';
        setTimeout(() => {
          this.copySuccessMessage = 'Copy link';
        }, 2000);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        this.copySuccessMessage = 'Failed to copy!';
      });
  }

  protected readonly CSocialMediaData = CSocialMediaData;
}
