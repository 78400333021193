import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { TRIM_DROPDOWN_OPTIONS } from '../../shared/constants';
import {
  IApiResponseWithData,
  IFileData,
  ITrimDropdownOption,
  ITrimmedLinksData,
} from '../../models';
import { OtrimFacade } from '../../services/otrim.facade';
import { ToastModule } from 'primeng/toast';
import FileSaver from 'file-saver';
import {
  catchError,
  combineLatest,
  debounceTime,
  map,
  mergeMap,
  Observable,
  startWith,
  switchMap,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { OButtonDirective, OToastV2Service } from 'o-suite-lib';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { loadingData } from '../shortified-url-table/url-table.const';

interface FormValues {
  immediateInputs: Array<any>;
  debouncedInput: any;
}

interface Data {
  aliasName: string;
  fromDate: string;
  toDate: string;
  pageNo: number;
}

@Component({
  selector: 'app-shortified-tables-filters',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    RadioButtonModule,
    CommonModule,
    CalendarModule,
    InputGroupModule,
    InputGroupAddonModule,
    FormsModule,
    InputIconModule,
    IconFieldModule,
    ToastModule,
    ReactiveFormsModule,
    OButtonDirective,
    FaIconComponent,
  ],
  templateUrl: './shortified-tables-filters.component.html',
  styleUrl: './shortified-tables-filters.component.scss',
})
export class ShortifiedTablesFiltersComponent implements OnInit {

  private oTrimFacade = inject(OtrimFacade);
  private fb = inject(FormBuilder);
  private oToastV2Service = inject(OToastV2Service);
  private destroyRef = inject(DestroyRef);

  protected readonly faSearch = faSearch;
  TRIM_DROPDOWN_OPTIONS = TRIM_DROPDOWN_OPTIONS;

  formGroup: FormGroup = this.fb.group({
    selectedOption: [this.TRIM_DROPDOWN_OPTIONS.ALL_LINK],
    date: [null],
    searchInput: [''],
  });


  @Input() selectedOption?: ITrimDropdownOption;
  @Output() selectedOptionChange = new EventEmitter<any>();

  trimTypeDropdownOptions: ITrimDropdownOption[] = Object.values(TRIM_DROPDOWN_OPTIONS);

  exportLoading: boolean = false;

  ngOnInit(): void {
    this.oTrimFacade.setTrimmedLinks([...Array(8).fill({ ...loadingData })]);
    this.oTrimFacade.setTrimmedFilesLinks([...Array(8).fill({ ...loadingData })]);

    const immediateInputs$ = [
      this.formGroup.get('selectedOption')!.valueChanges.pipe(startWith(this.formGroup.get('selectedOption')!.value)),
      this.formGroup.get('date')!.valueChanges.pipe(startWith(this.formGroup.get('date')!.value),
        map((date) => {
          return this.onDateChange(date);
        })
      ),
    ];

    const immediateInputsCombined$ = combineLatest(immediateInputs$);

    const debouncedControl$ = this.formGroup
      .get('searchInput')!
      .valueChanges.pipe(debounceTime(300), startWith(this.formGroup.get('searchInput')!.value));

    combineLatest([immediateInputsCombined$, debouncedControl$])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(
          ([immediateInputs, debouncedInput]): FormValues => ({
            immediateInputs,
            debouncedInput,
          })
        ),
        switchMap((formValues: FormValues) => {
          const { immediateInputs, debouncedInput } = formValues;
          this.selectedOptionChange.emit(immediateInputs[0]);

          const data: Data = {
            aliasName: debouncedInput,
            fromDate: immediateInputs[1]?.fromDate ?? '',
            toDate: immediateInputs[1]?.toDate ?? '',
            pageNo: 1,
          };

          if( immediateInputs[0].value == TRIM_DROPDOWN_OPTIONS.ALL_LINK.value)
              return this.getTrimmedLinks(data).pipe(
                map((results) => ({ links: results.result.data, files: null })));

          return this.getTrimmedFiles().pipe(
            map((results) => ({ links: null, files: results.result.data }))
          );
        }),
        catchError(() => {
          return [];
        })
      )
      .subscribe( {
        next:({ links, files }) => {
          if (links !== null) {
            this.oTrimFacade.setTrimmedLinks(links);
          }
          if (files !== null) {
            this.oTrimFacade.setTrimmedFilesLinks(files);
          }

          this.oTrimFacade.isLinksDataLoadingSignal.set(false);
        },
        error: () => {
          this.oTrimFacade.setTrimmedLinks([]);
          this.oTrimFacade.setTrimmedFilesLinks([]);

          this.oTrimFacade.isLinksDataLoadingSignal.set(false);
        }
      }
      );
  }

  exportLinksAsCsv() {
    this.exportLoading = true;
    return this.oTrimFacade
      .getDateRange()
      .pipe(
        mergeMap((res: any) => {
          return this.oTrimFacade.downloadLinksAsCsv(res);
        })
      )
      .subscribe(
        (data) => {
          this.saveFileAs(data, '.csv', 'O-Shortify');
          this.oToastV2Service.add({
            severity: 'success',
            summary: 'File Downloaded Successfully.',
            icon: environment.publicPath + '/assets/icons/toast/success.svg',
            life: environment.ToastMessageTime,
          });
          this.exportLoading = false;
        },
        (error) => {
          this.oToastV2Service.add({
            severity: 'error',
            summary: error?.message ? error.message : 'Something went wrong',
            icon: environment.publicPath + '/assets/icons/toast/error.svg',
            life: environment.ToastMessageTime,
          });
        }
      );
  }

  saveFileAs(response: any, type: any, fileName: any) {
    const blob = new Blob([response], { type: 'text/plain' });
    const file = fileName + type;
    FileSaver.saveAs(blob, file);
  }

  onDateChange(date: any[]) {
    if (date && date.length > 0) {
      const formatDate = (date: Date): string => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };

      const formattedDate1 = formatDate(new Date(date[0]));
      const formattedDate2 = formatDate(new Date(date[1]));

      this.oTrimFacade.setDateRange({
        fromDate: formattedDate1,
        toDate: formattedDate2,
      });

      return {
        fromDate: formattedDate1,
        toDate: formattedDate2,
      };
    }

    return null;
  }

  getTrimmedLinks(data: any): Observable<IApiResponseWithData<ITrimmedLinksData[]>> {
    return this.oTrimFacade.getTrimmedLinks(data);
  }

  getTrimmedFiles(): Observable<IApiResponseWithData<IFileData[]>> {
    const params = {
      pageSize: 50,
      pageIndex: 0,
      searchKey: '',
      orderBy: 'asc',
    };

   return  this.oTrimFacade.getAllFilesData(params)
  }
}
