<div class="mx-8 my-4 home__container">
  <h2>Create short URL</h2>
  <app-short-url-component />

  <div class="mt-4">
    <div class="flex align-items-center gap-4">
      <h2>Short URLs</h2>
      <div class="flex gap-3">
        <p-button label="List" (click)="onTabChange(true)" [outlined]="!showList" class="w-5rem font-semibold" />
        <p-button label="Insights" (click)="onTabChange(false)" [outlined]="showList" class="font-semibold" />
      </div>
    </div>
    <app-table-options [(selectedOption)]="selectedOption" />
  </div>

  <p-divider />

  <div class="flex gap-4 justify-content-between flex-wrap">
    @for (card of analyticsCards; track card.key) {
      <app-analytics-card-component
        class="flex-1"
        [header]="card.header"
        [data]="card.data"
        [icon]="card.icon"
        [backgroundColor]="card.background"
        [iconColor]="card.iconColor"
      />
    }
  </div>

  @if (showList) {
    <div class="mt-3 border-round-md bg-white p-3 home__table-container">
      @if (selectedOption.name === selecteOptions.ALL_LINK.name) {
        <app-url-table />
      } @else {
        <app-file-table />
      }
    </div>
  } @else {
    <app-statics />
  }
</div>
