<div class="statics__container">
  <div class="mt-3 border-round-md bg-white p-3 text-center statics-item__container">
    <app-map-chart [chartData]="worldMapChartData" />
  </div>
  <div class="statistics-container">
    <h2>Statics</h2>
    <div class="flex justify-content-between gap-4 flex-wrap">
      <div class="border-round-md bg-white p-3 flex-1 h-28rem statics-item__container">
        <app-pie-chart [chartData]="pieChartDeviceOptions" />
      </div>
      <div class="border-round-md bg-white p-3 flex-1 h-28rem overflow-auto statics-item__container">
        <h3 class="my-0 custom-text-color">City</h3>
        <app-city-list-view [cities]="cities" />
      </div>
      <div class="border-round-md bg-white p-3 flex-1 h-28rem statics-item__container">
        <app-pie-chart [chartData]="pieChartBrowserOptions" />
      </div>
      <div class="border-round-md bg-white p-3 flex-1 h-28rem overflow-auto statics-item__container">
        <h3 class="my-0 custom-text-color">Social media clicks</h3>
        <app-social-list-view [socialsData]="socialsData" />
      </div>
    </div>
  </div>
</div>
