import { Component, EventEmitter, inject, input, Input, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { Button } from 'primeng/button';
import { assetUrl } from '../../../../single-spa/asset-url';
import { OtrimFacade } from '../../../services/otrim.facade';
import { CSocialMediaData } from './social-media.const';
import { OButtonDirective } from 'o-suite-lib';
import { shareUrl } from '../../utils/url.util';

@Component({
  selector: 'app-share-dialog',
  standalone: true,
  imports: [DialogModule, Button, OButtonDirective],
  templateUrl: './share-dialog.component.html',
  styleUrl: './share-dialog.component.scss',
})
export class ShareDialogComponent {
  private otrimFacade = inject(OtrimFacade);
  socialMediaLinks = CSocialMediaData;

  @Input('visible') visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  oTrimLink = input.required<string>();
  @Output() fileUploaded = new EventEmitter<File>();
  copySuccessMessage: string = 'Copy link';
  @Output() navigateToFilesList = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.socialMediaLinks = CSocialMediaData.map((elem) => ({
      ...elem,
      url: this.generateSharingLink(elem.name.toLocaleLowerCase()),
    }));
  }

  closeDialog() {
    this.visible = false;
    this.visibleChange.emit(false);
  }

  copyToClipboard(text: string): void {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.copySuccessMessage = 'Copied to clipboard!';
        setTimeout(() => {
          this.copySuccessMessage = 'Copy link';
        }, 2000);
      })
      .catch((err) => {
        this.copySuccessMessage = 'Failed to copy!';
      });
  }

  viewLinkDetails() {
    this.closeDialog();
    this.navigateToFilesList.emit();
    this.otrimFacade.isScrollToLinksTableSignal.set(true);
  }

  generateSharingLink(platform: string): string {
    return shareUrl(platform, this.oTrimLink() + '');
  }

  protected readonly assetUrl = assetUrl;
}
