import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  Signal,
  signal,
  ViewChild,
} from '@angular/core';
import { TableModule } from 'primeng/table';
import { faEllipsis, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { OtrimFacade } from '../../services/otrim.facade';
import { ITrimmedLinksData } from '../../models';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { ContextMenu, ContextMenuModule } from 'primeng/contextmenu';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmModalComponent, OToastV2Service } from 'o-suite-lib';
import { ToastModule } from 'primeng/toast';
import { assetUrl } from '../../../single-spa/asset-url';
import { SkeletonModule } from 'primeng/skeleton';
import { CSocialMediaData } from '../../shared/shared-components/share-dialog/social-media.const';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { getLastUrlSegment, shareUrl } from '../../shared/utils/url.util';
import { deleteUrlConfirmationData } from './url-table.const';

@Component({
  selector: 'app-shortified-url-table',
  standalone: true,
  imports: [
    TableModule,
    FontAwesomeModule,
    CommonModule,
    TooltipModule,
    DropdownModule,
    ContextMenuModule,
    ConfirmDialogModule,
    ConfirmModalComponent,
    ToastModule,
    SkeletonModule,
    OverlayPanelModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './shortified-url-table.component.html',
  styleUrl: './shortified-url-table.component.scss',
})
export class ShortifiedUrlTableComponent  {
  private otrimFacade = inject(OtrimFacade);
  private router = inject(Router);
  private oToastV2Service = inject(OToastV2Service);

  @Input() selectedItem!: ITrimmedLinksData;
  @Output() selectedItemChange = new EventEmitter<any>();
  @ViewChild('urlTableElement') urlTableElement!: ElementRef;

  faEllipsis = faEllipsis;
  faShare = faShareAlt;
  trimmedLinks: Signal<ITrimmedLinksData[]> = signal<ITrimmedLinksData[]>([]);

  isShowConfirmModal: boolean = false;
  targetUrl?: ITrimmedLinksData;

  @ViewChild('contextMenuList') contextMenu1!: ContextMenu;
  @ViewChild('contextMenuShare') contextMenu2!: ContextMenu;

  activeContextMenu: any;
  socialMediaData = CSocialMediaData;

  contextMenuItems: MenuItem[] = [
    {
      label: 'Copy',
      command: () => {
        let linkToCopy = this.targetUrl!.shortEndUrl;
        navigator.clipboard
          .writeText(linkToCopy)
          .then(() => {
            this.oToastV2Service.add({
              severity: 'success',
              summary: 'Link copied to clipboard',
              icon: environment.publicPath + '/assets/icons/toast/success.svg',
              life: environment.ToastMessageTime,
            });
          })
          .catch((err) => {
            this.oToastV2Service.add({
              severity: 'error',
              summary: 'Could not copy link',
              icon: environment.publicPath + '/assets/icons/toast/error.svg',
              life: environment.ToastMessageTime,
            });
          });
      },
    },
    {
      label: 'Edit',
      command: () => {
        if (this.targetUrl) {
          const urlData = {...this.targetUrl, shortEndUrl: getLastUrlSegment(this.targetUrl?.shortEndUrl)}
          this.otrimFacade.setLinkToEdit(urlData);
          document.body.scrollTop = 0;
        }
      },
    },
    {
      label: 'Delete',
      command: () => {
        this.isShowConfirmModal = true;
      },
    },
  ];

  constructor() {
    effect(
      () => {
        this.trimmedLinks = this.otrimFacade.getTrimmedLinksRecords();
        const isScrollToLinksTable = this.otrimFacade.isScrollToLinksTable();
        setTimeout(()=>{
          if (isScrollToLinksTable) {
            this.urlTableElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        },100)
        this.otrimFacade.setIsScrollToLinksTable(false);
      },
      { allowSignalWrites: true }
    );
  }

  setTargetUrl(url: ITrimmedLinksData) {
    this.targetUrl = url;
    sessionStorage.setItem('targetShortEndUrl', url?.shortEndUrl);
  }

  closeOtherContextMenus(current: any) {
    if (this.activeContextMenu && this.activeContextMenu !== current) {
      this.activeContextMenu.hide();
    }
    this.activeContextMenu = current;
  }

  deleteFile() {
    this.otrimFacade.deleteTrimmedLink(this.targetUrl?.trimUrlId?.toString() || '').subscribe(
      () => {
        this.isShowConfirmModal = false;
        this.otrimFacade.deleteTrimmedLinkById(this.targetUrl?.trimUrlId || 0);
        this.closeConfirmDialog();
        this.oToastV2Service.add({
          severity: 'success',
          summary: 'Link Deleted Successfully.',
          icon: environment.publicPath + '/assets/icons/toast/success.svg',
          life: environment.ToastMessageTime,
        });
      },
      (error) => {
        this.isShowConfirmModal = false;
        this.oToastV2Service.add({
          severity: 'error',
          summary: error.error,
          icon: environment.publicPath + '/assets/icons/toast/error.svg',
          life: environment.ToastMessageTime,
        });
      }
    );
  }

  closeConfirmDialog() {
    this.isShowConfirmModal = false;
  }

  handleShowAnalytics(url: ITrimmedLinksData) {
    this.setTargetUrl(url);
    this.router.navigate(['/O-Shortify/link', url?.trimUrlId]);
  }

  generateSharingLink(platform: string, url: string) {
    return shareUrl(platform, url);
  }

  protected readonly environment = environment;
  protected readonly deleteUrlConfirmationData = deleteUrlConfirmationData;
  protected readonly assetUrl = assetUrl;
  protected isLinksDataLoading = this.otrimFacade.isLinksDataLoadingSignal;
}
