<o-confirm-modal
  [(visible)]="isShowConfirmModal"
  [data]="deleteUrlConfirmationData"
  [modalIcon]="environment.publicPath + '/assets/icons/t-alert.svg'"
  (acceptConfirmation)="deleteFile()"
  (rejectConfirmation)="closeConfirmDialog()"
></o-confirm-modal>
@if (trimmedLinks()!.length || isLinksDataLoading()) {
  <div class="url-table__container" #urlTableElement>
    <p-table [value]="trimmedLinks()" styleClass="p-datatable-sm w-full" responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th class="tabel-header-text">URLs</th>
          <th class="tabel-header-text">Alias</th>
          <th class="tabel-header-text">Clicks</th>
          <th class="tabel-header-text">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-url>
        @if (!isLinksDataLoading()) {
          <tr>
            <td class="col-6">
              <div class="flex flex-column">
                <p class="my-0">
                  <a
                    class="text-primary font-semibold text-lg my-0 no-underline"
                    target="_blank"
                    [href]="url.shortEndUrl"
                  >
                    {{ url.shortEndUrl }}
                  </a>
                </p>
                <p
                  [autoHide]="false"
                  [pTooltip]="url.destinationUrl"
                  tooltipPosition="top"
                  class="my-0 text-base mt-2 white-space-nowrap overflow-hidden text-overflow-ellipsis text-ellipsis table-custom-text"
                >
                  {{ url.destinationUrl }}
                </p>
              </div>
            </td>
            <td class="col-2">{{ url.aliasName }}</td>
            <td class="col-2">{{ url.clickCount }}</td>
            <td class="col-2">
              <div class="flex gap-4 icon-colors">
                <img [src]="assetUrl('icons/analytic.svg')" (click)="handleShowAnalytics(url)" alt="analytic" />
                <div class="cursor-pointer" #contextTargetShare>
                  <fa-icon [icon]="faShare" [size]="'lg'" />
                </div>
                <img [src]="assetUrl('icons/qr-code.svg')" pTooltip="Coming soon!" tooltipPosition="top" alt="" />
                <div #contextTarget class="cursor-pointer" (click)="setTargetUrl(url)">
                  <fa-icon [icon]="faEllipsis" [size]="'lg'" />
                </div>
                <p-contextMenu
                  #contextMenuList
                  [target]="contextTarget"
                  [model]="contextMenuItems"
                  [appendTo]="'body'"
                  triggerEvent="click"
                  (onShow)="closeOtherContextMenus(contextMenuList)"
                />
                <p-contextMenu
                  #contextMenuShare
                  [target]="contextTargetShare"
                  [model]="socialMediaData"
                  [appendTo]="'body'"
                  triggerEvent="click"
                  [baseZIndex]="1000"
                  styleClass="w-10rem"
                  title="Share"
                  (onShow)="closeOtherContextMenus(contextMenuShare)"
                >
                  <ng-template let-item pTemplate="item">
                    <a
                      [href]="generateSharingLink(item.name, url.shortEndUrl)"
                      target="_blank"
                      class="flex gap-1 px-3 py-2 cursor-pointer align-items-center align-items-center no-underline share-dialog__social-link border-round-md"
                    >
                      <img [src]="item.iconPath" width="22" alt="" />

                      <span class="share-dialog__social-card-text text-sm text-color-tertiary-500 font-medium">
                        {{ item.name }}
                      </span>
                    </a>
                  </ng-template>
                </p-contextMenu>
              </div>
            </td>
          </tr>
        } @else {
          <tr>
            <td class="col-6"><p-skeleton height="2rem" /></td>
            <td class="col-2"><p-skeleton height="2rem" /></td>
            <td class="col-2"><p-skeleton height="2rem" /></td>
            <td class="col-2"><p-skeleton height="2rem" /></td>
          </tr>
        }
      </ng-template>
    </p-table>
  </div>
} @else {
  <div class="w-full h-28rem flex flex-column gap-4 justify-content-center align-items-center url-table--empty-state">
    <img [src]="assetUrl('icons/empty-table.svg')" alt="empty-table" />
    <h2 class="my-0">No Short URLs</h2>
    <p class="font-medium text-center">
      Here, you can view all the trimmed URLs created, along with options
      <br />
      to manage and track each link by the number of clicks received.
    </p>
  </div>
}
