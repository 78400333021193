<div class="p-4 border-round-md short-url__container">
  <form [formGroup]="shortUrlForm" (ngSubmit)="onSubmit()">
    <div>
      <div class="field">
        <label for="url-for-shortening" class="text-sm font-medium pl-2">URL for shortening</label>
        <input
          id="url-for-shortening"
          formControlName="url"
          type="text"
          class="border-round-xl w-full"
          placeholder="Enter URL to be shortened"
          pInputText
          [ngClass]="{
            'ng-invalid ng-dirty': submitted && shortUrlForm.get('url')?.invalid,
          }"
        />
      </div>
      <div class="formgrid grid flex align-items-center">
        <div class="field col pr-2">
          <label for="custom-name" class="text-sm font-medium pl-2">Custom name</label>
          <input
            id="custom-name"
            formControlName="customName"
            type="text"
            class="border-round-xl w-full"
            placeholder="Enter custom name"
            pInputText
            [ngClass]="{
            'ng-invalid ng-dirty': submitted && shortUrlForm.get('customName')?.invalid,
          }"
          />
        </div>
        <div class="field col pr-2">
          <label for="short-url-preview" class="text-sm font-medium pl-2">Short URL preview</label>
          <input
            id="short-url-preview"
            formControlName="shortUrlPreview"
            type="text"
            class="border-round-xl w-full input--disabled"
            placeholder="https://Otrim.com/"
          />
        </div>
        <div class="pt-2"><span class="text-base font-medium">/</span></div>
        <div class="field col pl-3\2">
          <label for="alias-name" class="text-sm font-medium pl-2">Alias name</label>
          <input
            id="alias-name"
            formControlName="aliasName"
            type="text"
            class="border-round-xl p-2 w-full"
            placeholder="Enter Alias name"
            pInputText
            [ngClass]="{
            'ng-invalid ng-dirty': submitted && shortUrlForm.get('aliasName')?.invalid,
          }"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-content-between align-items-center mt-3">
      <div class="font-semibold upgrade-link flex gap-2 align-items-center">
        <fa-icon [icon]="faCrown"></fa-icon>
        <p class="my-0">
          Need to shorten multiple links at once?
          <a (click)="openBulkInsertionModal()" class="cursor-pointer">Click here</a>
        </p>
      </div>
      <p-button
        [label]="targetUrl ? 'Save' : 'Trim Link'"
        type="submit"
        severity="success"
        [loading]="isLoading"
        [disabled]="isLoading"
      />
    </div>
  </form>
</div>

<app-bulk-trim-dialog
  [(visible)]="isShowBulkModal"
  (fileUploaded)="openCloseSuccessModal($event)"
></app-bulk-trim-dialog>

<o-dialog [(visible)]="isShowSuccessBulkModal" [data]="successModalData" (onClick)="reviewLinks()"></o-dialog>

<app-file-links-dialog [(visible)]="isShowFileModal" [fileInfo]="fileData"></app-file-links-dialog>

<app-share-dialog [(visible)]="isShowSuccessTrimModal" [oTrimLink]="trimmedUrl"></app-share-dialog>
