<div class="h-9rem border-round-md px-2 py-0 bg-white flex gap-2 align-items-baseline analytics-card__container">
  <div
    class="border-circle w-2rem h-2rem flex justify-content-center align-items-center"
    [style.background]="backgroundColor"
  >
    <fa-icon [icon]="icon" [size]="'sm'" [ngStyle]="{'color':iconColor}" />
  </div>
  <div>
    <p class="font-medium custom-text">{{ header }}</p>
    <div class="mt-0 font-bold text-6xl">{{ data | number:'1.0-0' }}</div>
  </div>
</div>
