import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { TRIM_DROPDOWN_OPTIONS } from '../../shared/constants';
import { IApiResponseWithData, IRangeDatePayloads, ITrimDropdownOption, ITrimmedLinksData } from '../../models';
import { OtrimFacade } from '../../services/otrim.facade';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import FileSaver from 'file-saver';
import { debounceTime, distinctUntilChanged, mergeMap, Observable, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-table-options',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    RadioButtonModule,
    CommonModule,
    CalendarModule,
    InputGroupModule,
    InputGroupAddonModule,
    FormsModule,
    InputIconModule,
    IconFieldModule,
    ToastModule,
    ReactiveFormsModule,
  ],
  providers: [MessageService],
  templateUrl: './table-options.component.html',
  styleUrl: './table-options.component.scss',
})
export class TableOptionsComponent implements OnInit {
  @Input() selectedOption?: ITrimDropdownOption;

  @Output() selectedOptionChange = new EventEmitter<any>();
  private otrimFacade = inject(OtrimFacade);
  private messageService = inject(MessageService);
  searchControl = new FormControl('');

  date!: Date[];
  exportLoading: boolean = false;

  getOptionsArray() {
    return Object.values(TRIM_DROPDOWN_OPTIONS);
  }

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(700),
        distinctUntilChanged(),
        switchMap((query) => this.performSearch(query ? query : ''))
      )
      .subscribe({
        next: (results: IApiResponseWithData<ITrimmedLinksData[]>) => {
          this.otrimFacade.setFilteredLinks(results.result.data);
        },
        error: (err) => console.error('Error:', err),
      });
  }

  performSearch(query: string): Observable<IApiResponseWithData<ITrimmedLinksData[]>> {
    this.onOptionChange(TRIM_DROPDOWN_OPTIONS.ALL_LINK);

    return this.otrimFacade.getDateRange().pipe(
      mergeMap((res: IRangeDatePayloads): Observable<IApiResponseWithData<ITrimmedLinksData[]>> => {
        return this.otrimFacade.searchAlias({
          aliasName: query,
          pageNo: 1,
          ...res,
        });
      })
    );
  }

  onOptionChange(newValue: any): void {
    this.selectedOptionChange.emit(newValue);
  }

  exportLinksAsCsv() {
    this.exportLoading = true;
    return this.otrimFacade
      .getDateRange()
      .pipe(
        mergeMap((res: any) => {
          return this.otrimFacade.downloadLinksAsCsv(res);
        })
      )
      .subscribe((data) => {
        this.saveFileAs(data, '.csv', 'O-Trim');
        this.messageService.add({
          severity: 'success',
          detail: 'File Downloaded Successfully',
          key: 'br',
          life: environment.ToastMessageTime,
        });
        this.exportLoading = false;
      });
  }

  saveFileAs(response: any, type: any, fileName: any) {
    const blob = new Blob([response], { type: 'text/plain' });
    const file = fileName + type;
    FileSaver.saveAs(blob, file);
  }

  onDateChange() {
    if (this.date[0] && this.date[1]) {
      const formatDate = (date: Date): string => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };

      const formattedDate1 = formatDate(new Date(this.date[0]));
      const formattedDate2 = formatDate(new Date(this.date[1]));

      this.otrimFacade.setDateRange({
        fromDate: formattedDate1,
        toDate: formattedDate2,
      });
    }
  }
}
