import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { TableModule } from 'primeng/table';
import { AnalyticsCardComponent } from '../../shared/shared-components/analytics-card/analytics-card-component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { StaticsComponent } from '../../components/Statics/statics.component';
import { MenuItem } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { IAnalyticCard, IDashboardPayload } from '../../models';
import { OtrimFacade } from '../../services/otrim.facade';
import { mergeMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ANALYTIC_CARDS_DATA } from '../../shared/constants';
import { environment } from '../../../environments/environment.standalone';

@Component({
  selector: 'app-link',
  standalone: true,
  imports: [
    FontAwesomeModule,
    TableModule,
    ButtonModule,
    DividerModule,
    CommonModule,
    AnalyticsCardComponent,
    BreadcrumbModule,
    StaticsComponent,
    CalendarModule,
    FormsModule,
  ],
  templateUrl: './link.component.html',
  styleUrl: './link.component.scss',
})
export class LinkComponent implements OnInit {
  private otrimFacade = inject(OtrimFacade);
  private route = inject(ActivatedRoute);
  shortEndUrl: string = '';
  shortEndTerm: string = '';
  date!: Date;
  analyticsCards: IAnalyticCard[] = ANALYTIC_CARDS_DATA.filter(
    (item: IAnalyticCard) => !['totalLinks', 'brokenLinks'].includes(item.key)
  );
  breadcrumbItems: MenuItem[] = [{ label: 'Main Screen', routerLink: '/', isRoot: true }, { label: 'temp' }];

  ngOnInit() {
    this.shortEndTerm = sessionStorage.getItem('targetShortEndUrl') || '';
    this.breadcrumbItems[1].label = this.shortEndTerm;
    this.shortEndUrl = environment.otrimApiUrl + this.shortEndTerm;
    this.route.paramMap.subscribe((params) => {
      this.getDashboardData({
        type: 'trim',
        fromDate: '',
        toDate: '',
        campaignId: '',
        trimurlId: Number(params.get('id')),
      });
    });
  }

  getDashboardData(params: IDashboardPayload) {
    this.otrimFacade
      .getDateRange()
      .pipe(
        mergeMap((res: any) => {
          return this.otrimFacade.getDashboardData({ ...params, ...res });
        })
      )
      .subscribe((res: any) => {
        this.analyticsCards = this.analyticsCards.map((card) => ({
          ...card,
          data: res.result[card.key],
        }));
        this.otrimFacade.setDashboardStatistics(res?.result);
      });
  }
}
