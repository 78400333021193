import { assetUrl } from '../../../../single-spa/asset-url';

interface ISocialMediaData {
  id: string;
  name: string;
  iconPath: string;
  url?: string;
}

export const CSocialMediaData: ISocialMediaData[] = [
  {
    id: '1',
    name: 'Whatsapp',
    iconPath: assetUrl('/social-media/whatsapp.png'),
  },
  {
    id: '2',
    name: 'Facebook',
    iconPath: assetUrl('/social-media/facebook.png'),
  },
  {
    id: '3',
    name: 'LinkedIn',
    iconPath: assetUrl('/social-media/linkedin.png'),
  },
  {
    id: '4',
    name: 'X',
    iconPath: assetUrl('/social-media/x.png'),
  },
  {
    id: '5',
    name: 'Threads',
    iconPath: assetUrl(`/social-media/threads.png`),
  },
  {
    id: '6',
    name: 'Email',
    iconPath: assetUrl(`/social-media/email.png`),
  },
];
