<div class="p-4 border-round-md short-url__container">
  <form [formGroup]="shortUrlForm" (ngSubmit)="onSubmit()">
    <div>
      <div class="field">
        <label for="url-for-shortening" class="text-sm font-medium pl-2">URL for shortening</label>
        <input
          id="url-for-shortening"
          formControlName="url"
          type="text"
          class="border-round-xl w-full"
          placeholder="Enter URL to be shortened"
          pInputText
          [ngClass]="{
            'ng-invalid ng-dirty': shortUrlForm.get('url')?.touched && shortUrlForm.get('url')?.invalid,
          }"
        />
        @if (shortUrlForm.get('url')?.touched && shortUrlForm.get('url')?.errors?.['required']) {
        <small class="text-red-500">URL is required!</small>
        }@if (shortUrlForm.get('url')?.touched && shortUrlForm.get('url')?.errors?.['maxlength']) {
        <small class="text-red-500">URL cannot exceed 2000 characters.</small>
        }@if (shortUrlForm.get('url')?.touched && shortUrlForm.get('url')?.errors?.['invalidUrl']) {
        <small class="text-red-500">Please enter a valid URL.</small>
        }
      </div>
      <div class="formgrid grid flex align-items-center">
        <div class="field col pr-2">
          <label for="custom-name" class="text-sm font-medium pl-2">Custom name</label>
          <input
            id="custom-name"
            formControlName="customName"
            type="text"
            class="border-round-xl w-full"
            placeholder="Enter custom name"
            pInputText
            [ngClass]="{
            'ng-invalid ng-dirty': shortUrlForm.get('customName')?.touched && shortUrlForm.get('customName')?.invalid,
          }"
          />
          @if (shortUrlForm.get('customName')?.touched && shortUrlForm.get('customName')?.errors?.['minlength']) {
          <small class="text-red-500">Alias name must be at least 3 characters long.</small>
          } @if (shortUrlForm.get('customName')?.touched && shortUrlForm.get('customName')?.errors?.['maxlength']) {
          <small class="text-red-500">Alias name cannot exceed 16 characters.</small>
          }
        </div>
        <div class="field col pr-2">
          <label for="short-url-preview" class="text-sm font-medium pl-2">Short URL preview</label>
          <input
            id="short-url-preview"
            formControlName="shortUrlPreview"
            type="text"
            class="border-round-xl w-full input--disabled"
            placeholder="https://Otrim.com/"
          />
        </div>
        <div class="pt-2"><span class="text-base font-medium">/</span></div>
        <div class="field col pl-3\2">
          <label for="alias-name" class="text-sm font-medium pl-2">Alias name</label>
          <input
            id="alias-name"
            formControlName="aliasName"
            type="text"
            class="border-round-xl p-2 w-full"
            placeholder="Enter Alias name"
            pInputText
            [ngClass]="{
            'ng-invalid ng-dirty': shortUrlForm.get('aliasName')?.touched && shortUrlForm.get('aliasName')?.invalid,
          }"
          />
          @if (shortUrlForm.get('aliasName')?.touched && shortUrlForm.get('aliasName')?.errors?.['minlength']) {
          <small class="text-red-500">Alias name must be at least 2 characters long.</small>
          } @if (shortUrlForm.get('aliasName')?.touched && shortUrlForm.get('aliasName')?.errors?.['maxlength']) {
          <small class="text-red-500">Alias name cannot exceed 16 characters.</small>
          }
        </div>
      </div>
    </div>
    <div class="flex justify-content-between align-items-center mt-3">
      <div class="font-semibold upgrade-link flex gap-2 align-items-center">
        <fa-icon [icon]="faCrown"></fa-icon>
        <p class="my-0">
          Need to shorten multiple links at once?
          <a (click)="openBulkInsertionModal()" class="cursor-pointer">Click here</a>
        </p>
      </div>
      <p-button
        o-button
        oButtonSeverity="success"
        [label]="targetUrl() ? 'Save' : 'Trim Link'"
        type="submit"
        severity="success"
        [loading]="isLoading"
        [disabled]="isLoading"
      />
    </div>
  </form>
</div>

<app-bulk-trim-dialog [(visible)]="isShowBulkModal" (fileUploaded)="openCloseSuccessModal($event)" />

<o-dialog [(visible)]="isShowSuccessBulkModal" [data]="successModalData" (onClick)="reviewLinks()" />

<app-file-links-dialog [(visible)]="isShowFileModal" [fileInfo]="fileData" />

@if(isShowSuccessTrimModal) {
  <app-share-dialog
    [(visible)]="isShowSuccessTrimModal"
    [oTrimLink]="trimmedUrl"
    (close)="isShowSuccessTrimModal = false"
    (navigateToFilesList)="navigateToFilesListTable()"
  />
}

