export function shareUrl(platform: string, url: string): string {
  switch (platform.toLowerCase()) {
    case 'whatsapp':
      return `https://api.whatsapp.com/send?text=${url}`;
    case 'facebook':
      return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    case 'linkedin':
      return `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
    case 'x':
      return `https://twitter.com/share?url=${url}`;
    case 'threads':
      return `https://www.threads.net/intent/post?text=${url}`;
    case 'email':
      return `mailto:?subject=Check this out&body=${url}`;
    default:
      return '#';
  }
}

export function getLastUrlSegment(url?: string): string {
  if (!url) return "";

  const segments = url.split("/");
  return segments.pop() || "";
}
