import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AvatarModule } from 'primeng/avatar';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-analytics-card-component',
  standalone: true,
  imports: [FontAwesomeModule, AvatarModule, CommonModule],
  providers: [DecimalPipe],

  templateUrl: './analytics-card-component.html',
  styleUrl: './analytics-card-component.scss',
})
export class AnalyticsCardComponent {
  @Input() header!: string;
  @Input() data!: number;
  @Input() icon!: any;
  @Input() backgroundColor!: string;
  @Input() iconColor!: string;
}
