import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { EChartsOption } from 'echarts';
import { provideEcharts } from 'ngx-echarts';
import { ButtonModule } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';
import { MapChartComponent } from '../../shared/shared-components/charts/map-chart/map-chart.component';
import { PieChartComponent } from '../../shared/shared-components/charts/pie-chart/pie-chart.component';
import { CityListViewComponent } from '../../shared/shared-components/city-list-view/city-list-view.component';
import { ISocialListItemModel, ICountryCode, IDashboard, ICityChart } from '../../models';
import { SocialListViewComponent } from '../../shared/shared-components/social-list-view/social-list-view.component';
import { OtrimFacade } from '../../services/otrim.facade';

@Component({
  selector: 'app-statics',
  standalone: true,
  imports: [
    CommonModule,
    DataViewModule,
    ButtonModule,
    MapChartComponent,
    PieChartComponent,
    CityListViewComponent,
    SocialListViewComponent,
  ],
  providers: [provideEcharts()],
  templateUrl: './statics.component.html',
  styleUrl: './statics.component.scss',
})
export class StaticsComponent implements OnInit {
  pieChartDeviceOptions!: EChartsOption;
  pieChartBrowserOptions!: EChartsOption;
  worldMapChartData = signal<any>(null);

  private otrimFacade = inject(OtrimFacade);

  cities!: ICityChart[];
  socialsData!: ISocialListItemModel[];

  ngOnInit(): void {
    this.otrimFacade.getDashboardStatistics().subscribe((res: IDashboard | null) => {
      if (!res) return;

      this.cities = res?.cityChart;
      this.socialsData = res?.sharesChart;
      this.worldMapChartData.set(res?.countryChart.filter(({ countryCode }: ICountryCode) => !!countryCode));

      const totalDevice = res?.deviceChart?.reduce((sum: any, item: any) => sum + item.value, 0);
      const totalBrowser = res?.browserChart.reduce((sum: any, item: any) => sum + item.value, 0);

      this.pieChartDeviceOptions = {
        title: {
          text: 'Device Type',
          textStyle: {
            color: '#344f60',
          },
        },
        tooltip: {
          trigger: 'item',
        },
        legend: [
          {
            itemGap: 18,
            orient: 'vertical',
            left: 'left',
            bottom: 30,
            align: 'auto',
            formatter: function (name) {
              return name;
            },
            textStyle: {
              fontWeight: 500,
              color: '#344F60',
              fontSize: 16,
            },
          },
          {
            itemGap: 18,
            orient: 'vertical',
            left: 'right',
            bottom: 30,
            formatter: function (name) {
              const item: any = res.deviceChart.find((d: any) => d.name === name);
              if (item) {
                const percentage = ((item.value / totalDevice) * 100).toFixed(2);
                return `${Math.ceil(parseInt(percentage))}%`;
              }
              return name;
            },
            textStyle: {
              fontWeight: 700,
              color: '#344F60',
              fontSize: 16,
            },
            icon: 'none',
          },
        ],
        series: [
          {
            height: '75%',
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: res.deviceChart,
          },
        ],
      };
      this.pieChartBrowserOptions = {
        title: {
          text: 'Browser Type',
          textStyle: {
            color: '#344f60',
          },
        },
        tooltip: {
          trigger: 'item',
        },
        legend: [
          {
            itemGap: 18,
            orient: 'vertical',
            left: 'left',
            bottom: 0,
            formatter: function (name) {
              return name;
            },
            textStyle: {
              fontWeight: 500,
              color: '#344F60',
              fontSize: 16,
            },
          },
          {
            itemGap: 18,
            orient: 'vertical',
            left: 'right',
            bottom: 0,
            formatter: function (name) {
              const item = res.browserChart.find((d: any) => d.name === name);
              if (item) {
                const percentage = ((item.value / totalBrowser) * 100).toFixed(2);
                return `${Math.ceil(parseInt(percentage))}%`;
              }
              return name;
            },
            textStyle: {
              fontWeight: 700,
              color: '#344F60',
              fontSize: 16,
            },
            icon: 'none',
          },
        ],
        series: [
          {
            height: '75%',
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: res.browserChart,
          },
        ],
      };
    });
  }
}
