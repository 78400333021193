import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  IApiResponseWithData,
  IDashboard,
  IApiResponse,
  IDashboardPayload,
  IFileData,
  IFileLinksPayload,
  IFilesPayload,
  IFileTrimmedLinksData,
  ITrimmedLinksData,
  IRangeDatePayloads,
  ITrimSingleLink,
  IBulkTemplatePayload,
  ISearchAliasPayload,
} from '../models';
import { map, Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class OtrimApi {
  otrim_ApiUrl = environment.otrimApiUrl;
  bearerToken = environment.bearerToken;

  private httpClient = inject(HttpClient);
  // todo: remove token and use it from cookies
  private headers = new HttpHeaders({
    accept: '*/*',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${this.bearerToken}`,
  });

  private readonly options = {
    headers: this.headers,
    withCredentials: true,
  };

  insertCustomTrim(payload: ITrimSingleLink): Observable<IApiResponseWithData<ITrimmedLinksData>> {
    return this.httpClient
      .post(this.otrim_ApiUrl + 'v2/insertURL', payload, this.options)
      .pipe(map((response) => response as IApiResponseWithData<ITrimmedLinksData>));
  }

  getDashboardData(payload: IDashboardPayload): Observable<IApiResponse<IDashboard>> {
    return this.httpClient
      .post(this.otrim_ApiUrl + 'dashboard', payload, this.options)
      .pipe(map((response) => response as IApiResponse<IDashboard>));
  }

  getTrimAnalytics(payload: IRangeDatePayloads): Observable<IApiResponseWithData<ITrimmedLinksData[]>> {
    return this.httpClient
      .post(this.otrim_ApiUrl + 'getTrimUrlDataServerSide', payload, this.options)
      .pipe(map((response) => response as IApiResponseWithData<ITrimmedLinksData[]>));
  }

  getAllFilesData(payload: IFilesPayload): Observable<IApiResponseWithData<IFileData[]>> {
    return this.httpClient
      .post(this.otrim_ApiUrl + 'getAllFilesData', payload, this.options)
      .pipe(map((response) => response as IApiResponseWithData<IFileData[]>));
  }

  fetchRecordByFile(payload: IFileLinksPayload): Observable<IApiResponseWithData<IFileTrimmedLinksData[]>> {
    return this.httpClient
      .post(this.otrim_ApiUrl + 'getAllRecordsByFileId', payload, this.options)
      .pipe(map((response) => response as IApiResponseWithData<IFileTrimmedLinksData[]>));
  }

  getSampleCsv(payload: IBulkTemplatePayload): Observable<Blob> {
    return this.httpClient.post(this.otrim_ApiUrl + 'download/csvSample/', payload, {
      ...this.options,
      responseType: 'blob',
    });
  }

  checkFileName(url: any, params: any): Observable<IApiResponseWithData<string>> {
    // todo: remove token and use it from cookies
    let formDataHeader = new HttpHeaders({
      accept: '*/*',
      Authorization: `Bearer ${this.bearerToken}`,
    });
    return this.httpClient
      .post(this.otrim_ApiUrl + url, params, { headers: formDataHeader, withCredentials: true })
      .pipe(map((response) => response as IApiResponseWithData<string>));
  }

  getTrimUrlsCsv(payload: IRangeDatePayloads) {
    return this.httpClient.post(this.otrim_ApiUrl + 'exporttrimUrlRecordcsvReport', payload, {
      ...this.options,
      responseType: 'blob',
    });
  }

  exportFileAsCsv(payload: string) {
    const encryptedParams = CryptoJS.AES.encrypt(payload, environment.DEFAULT_KEY)
      .toString()
      .replace(/\//g, '_')
      .replace(/\+/g, '-');

    return this.httpClient.get(this.otrim_ApiUrl + 'trimUrlRecordcsvReport/' + encryptedParams, {
      ...this.options,
      responseType: 'blob',
    });
  }

  searchAlias(payload: ISearchAliasPayload): Observable<IApiResponseWithData<ITrimmedLinksData[]>> {
    return this.httpClient
      .post(this.otrim_ApiUrl + 'searchAliasName', payload, this.options)
      .pipe(map((response) => response as IApiResponseWithData<ITrimmedLinksData[]>));
  }

  deleteFile(fileId: string): Observable<IApiResponseWithData<string>> {
    const encryptParams = CryptoJS.AES.encrypt(fileId, environment.DEFAULT_KEY)
      .toString()
      .replace(/\//g, '_')
      .replace(/\+/g, '-');
    return this.httpClient
      .delete(this.otrim_ApiUrl + 'deleteFile/' + encryptParams, this.options)
      .pipe(map((response) => response as IApiResponseWithData<string>));
  }

  deleteTrimmedLink(payload: string) {
    const encryptParams = CryptoJS.AES.encrypt(payload, environment.DEFAULT_KEY)
      .toString()
      .replace(/\//g, '_')
      .replace(/\+/g, '-');
    return this.httpClient.delete(this.otrim_ApiUrl + 'deleteURL/' + encryptParams, this.options);
  }

  UpdatedTrimmedLink(payload: any) {
    return this.httpClient.put(this.otrim_ApiUrl + 'updateTrimUrl', payload, this.options);
  }
}
