import { Component, inject, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { OtrimFacade } from '../../services/otrim.facade';
import { BulkTrimDialogComponent } from '../../shared/shared-components/bulk-trim-dialog/bulk-trim-dialog.component';
import { DialogComponent } from 'o-suite-lib';
import { IActionDialogData, IApiResponseWithData, IFileStatData, ITrimmedLinksData } from '../../models';
import { FileLinksDialogComponent } from '../../shared/shared-components/file-links-modal/file-links-dialog.component';
import { ShareDialogComponent } from '../../shared/shared-components/share-dialog/share-dialog.component';
import { environment } from '../../../environments/environment.standalone';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-short-url-component',
  standalone: true,
  imports: [
    ButtonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    BulkTrimDialogComponent,
    DialogComponent,
    FileLinksDialogComponent,
    ShareDialogComponent,
    ToastModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './short-url-component.html',
  styleUrl: './short-url-component.scss',
})
export class ShortUrlComponent implements OnInit {
  faCrown = faCrown;
  shortUrlForm!: FormGroup;
  isLoading: boolean = false;
  isShowBulkModal: boolean = false;
  isShowSuccessBulkModal: boolean = false;
  isShowFileModal: boolean = false;
  fileData: IFileStatData | null = null;
  submitted: boolean = false;
  targetUrl: ITrimmedLinksData | null = null;
  isShowSuccessTrimModal: boolean = false;
  trimmedUrl: string = '';

  successModalData: IActionDialogData = { title: '', description: '', imageSrc: '', buttonState: null, buttonText: '' };

  private fb = inject(FormBuilder);
  private otrimFacade = inject(OtrimFacade);

  ngOnInit(): void {
    this.shortUrlForm = this.fb.group({
      url: ['', [Validators.required, Validators.maxLength(2000)]],
      customName: ['', [Validators.minLength(3), Validators.maxLength(30)]],
      aliasName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(16)]],
      shortUrlPreview: [{ value: 'https://Otrim.com/', disabled: true }],
    });

    this.otrimFacade.getLinkToEdit().subscribe((link: ITrimmedLinksData | null) => {
      this.targetUrl = link;

      if (this.targetUrl) {
        this.shortUrlForm.get('url')?.setValue(this.targetUrl?.destinationUrl);
        this.shortUrlForm.get('aliasName')?.setValue(this.targetUrl?.aliasName);
        this.shortUrlForm.get('customName')?.setValue(this.targetUrl?.shortEndUrl);

        this.shortUrlForm.get('url')?.disable();
        this.shortUrlForm.get('customName')?.disable();
      }
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.shortUrlForm.valid) {
      if (!this.targetUrl) {
        this.isLoading = true;
        this.otrimFacade
          .trimSingleLink({
            url: this.shortUrlForm.get('url')?.value,
            customName: this.shortUrlForm.get('customName')?.value,
            aliasName: this.shortUrlForm.get('aliasName')?.value,
            isSpamUrl: false,
          })
          .subscribe(
            (res: IApiResponseWithData<ITrimmedLinksData>) => {
              // todo: use data in the success modal
              this.isLoading = false;
              this.isShowSuccessTrimModal = true;
              this.trimmedUrl = environment.otrimApiUrl + res?.result?.data?.shortEndUrl;
              this.otrimFacade.addTrimmedLink(res?.result?.data);
              this.shortUrlForm.reset();
            },
            (error) => {
              console.error(error);

              this.isLoading = false;
              this.isShowSuccessBulkModal = true;

              this.successModalData = {
                title: 'Invalid link',
                description: 'Sorry, the link you provided is invalid and will not be trimmed',
                imageSrc: 'invalid.svg',
                extraLink: this.shortUrlForm.get('url')?.value,
                buttonState: 'primary',
                buttonText: 'Create a new short URL',
              };
            }
          );
      } else {
        const params = {
          inpurl: this.shortUrlForm.get('url')?.value,
          customName: this.shortUrlForm.get('customName')?.value,
          aliasName: this.shortUrlForm.get('aliasName')?.value,
          trimUrlId: this.targetUrl?.trimUrlId,
        };

        this.isLoading = true;
        this.otrimFacade.UpdatedTrimmedLink(params).subscribe(
          () => {
            this.isLoading = false;
            this.shortUrlForm.reset();
          },
          (error) => {
            this.isLoading = false;
            console.error(error);
          }
        );
      }
    }
  }

  openBulkInsertionModal() {
    // todo: check user subscription and change the logic depends on it.
    this.isShowBulkModal = true;
  }

  openCloseSuccessModal(value?: IFileStatData) {
    if (!this.isShowSuccessBulkModal && value) {
      this.fileData = value;
      this.successModalData = {
        title: 'Bulk file imported successfully! ',
        description: `Your file with ${value.totalLinks - value.invalidLinks} out of ${value.totalLinks} results has been successfully imported and is now ready to use!`,
        imageSrc: 'success.svg',
        buttonState: 'primary',
        buttonText: 'Review shorten links',
      };
    }
    this.isShowBulkModal = false;
    this.isShowSuccessBulkModal = !this.isShowSuccessBulkModal;
  }

  reviewLinks() {
    if (this.successModalData.title === 'Invalid link') {
      this.isShowSuccessBulkModal = false;
      this.shortUrlForm.reset();
    } else {
      this.isShowSuccessBulkModal = false;
      this.isShowFileModal = true;
    }
  }
}
