import { Component, ElementRef, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { Button } from 'primeng/button';
import { OtrimFacade } from '../../../services/otrim.facade';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import FileSaver from 'file-saver';
import { environment } from '../../../../environments/environment';
import CryptoJS from 'crypto-js';
import { IApiResponseWithData, IFileStatData } from '../../../models';
import { mergeMap, of } from 'rxjs';

@Component({
  selector: 'app-bulk-trim-dialog',
  standalone: true,
  imports: [DialogModule, Button, ToastModule],
  providers: [MessageService],
  templateUrl: './bulk-trim-dialog.component.html',
  styleUrl: './bulk-trim-dialog.component.scss',
})
export class BulkTrimDialogComponent {
  @Input() visible: boolean = false;
  @Output() fileUploaded = new EventEmitter<IFileStatData>();
  @Output() visibleChange = new EventEmitter<boolean>();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  closeDialog() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  file: File | null = null;
  isDragOver = false;
  private otrimFacade = inject(OtrimFacade);
  private messageService = inject(MessageService);

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.setFile(input.files[0]);
    }
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;

    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      this.setFile(event.dataTransfer.files[0]);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(): void {
    this.isDragOver = false;
  }

  setFile(file: File): void {
    this.file = file;

    this.bulkInsertLinks();
  }

  bulkInsertLinks(): void {
    if (this.file) {
      let fd = new FormData();
      fd.append('file', this.file);
      const encryptParams = CryptoJS.AES.encrypt(this.file.name, environment.DEFAULT_KEY).toString();
      fd.append('fileName', encryptParams);

      this.otrimFacade
        .fileUploader('checkFileName', fd)
        .pipe(
          mergeMap((res: IApiResponseWithData<string>): any => {
            if (res?.result?.data === 'FileName not exist' && this.file) {
              let fd = new FormData();
              fd.append('file', this.file);
              return this.otrimFacade.fileUploader('csvFileupload2', fd);
            }
            return of(res?.result?.data);
          })
        )
        .subscribe((res: any) => {
          if (res?.status !== 'Success') {
            this.messageService.add({
              severity: 'error',
              detail: res,
              key: 'br',
              life: environment.ToastMessageTime,
            });
            this.file = null;
            this.fileInput.nativeElement.value = '';
          } else {
            this.messageService.add({
              severity: 'success',
              detail: res?.message,
              key: 'br',
              life: environment.ToastMessageTime,
            });
            this.file = null;
            this.fileInput.nativeElement.value = '';
            this.closeDialog();
            this.fileUploaded.emit(res?.result);
          }
        });
    }
  }

  downloadTemplateFile() {
    const obj = { csvSampleType: 'BulkCustomTrim' };
    this.otrimFacade.getSampleCsv(obj).subscribe((res) => {
      this.saveFileAs(res, '.csv', 'O-Trim bulk file');
      this.messageService.add({
        severity: 'success',
        detail: 'Sample CSV downloaded successfully',
        key: 'br',
        life: environment.ToastMessageTime,
      });
    });
  }

  saveFileAs(response: any, type: any, fileName: any) {
    const blob = new Blob([response], { type: 'text/plain' });
    const file = fileName + type;
    FileSaver.saveAs(blob, file);
  }
}
