<p-toast position="bottom-right" key="br" />

<div class="flex justify-content-between align-items-center table-options__container">
  <div class="flex gap-2">
    <p-dropdown
      [options]="getOptionsArray()"
      [(ngModel)]="selectedOption"
      (ngModelChange)="onOptionChange($event)"
      optionLabel="name"
    >
      <ng-template let-option pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div class="flex gap-2">
            <p-radioButton [(ngModel)]="selectedOption" [value]="option" />
            <span>
              {{ option.name }}
            </span>
          </div>
        </div>
      </ng-template>
    </p-dropdown>

    <p-calendar
      placeholder="Choose date"
      [(ngModel)]="date"
      [showIcon]="true"
      [iconDisplay]="'input'"
      selectionMode="range"
      (onSelect)="onDateChange()"
    />
  </div>
  <div class="flex gap-2 align-items-center">
    <p-iconField iconPosition="left">
      <p-inputIcon styleClass="pi pi-search" />
      <input type="text" pInputText placeholder="Search Alias" [formControl]="searchControl" />
    </p-iconField>
    <p-button
      label="Export CSV"
      (click)="exportLinksAsCsv()"
      [loading]="exportLoading"
      [disabled]="exportLoading"
      [outlined]="true"
      class="font-semibold"
    />
  </div>
</div>
