import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ITrimmedLinksData, IDashboard, IFileData, IRangeDatePayloads } from '../models';

@Injectable({
  providedIn: 'root',
})
export class OtrimState {
  dashboardStatistics = new BehaviorSubject<IDashboard | null>(null);
  filteredLinks = new BehaviorSubject<ITrimmedLinksData[] | null>(null);
  FilesRecords = new BehaviorSubject<IFileData[] | null>(null);
  dateRange = new BehaviorSubject<IRangeDatePayloads>({ fromDate: '', toDate: '' });
  trimmedLinks = new BehaviorSubject<ITrimmedLinksData[] | null>(null);
  linkToEdit = new BehaviorSubject<ITrimmedLinksData | null>(null);

  setDashboardStatistics(stat: IDashboard) {
    this.dashboardStatistics.next(stat);
  }

  getDashboardStatistics(): Observable<IDashboard | null> {
    return this.dashboardStatistics.asObservable();
  }

  setFilteredLinks(data: ITrimmedLinksData[]): void {
    this.filteredLinks.next(data);
  }

  getFilteredLinks(): Observable<ITrimmedLinksData[] | null> {
    return this.filteredLinks.asObservable();
  }

  setFilesRecords(data: IFileData[]) {
    this.FilesRecords.next(data);
  }

  getFilesRecords(): Observable<IFileData[] | null> {
    return this.FilesRecords.asObservable();
  }

  deleteFileRecord(fileId: number): void {
    const updatedRecords = this.FilesRecords.value?.filter((record) => record.fileId !== fileId) || [];
    this.FilesRecords.next(updatedRecords);
  }

  setDateRange(data: IRangeDatePayloads) {
    this.dateRange.next(data);
  }

  getDateRange(): Observable<IRangeDatePayloads> {
    return this.dateRange.asObservable();
  }

  setTrimmedLinks(data: ITrimmedLinksData[]) {
    this.trimmedLinks.next(data);
  }

  getTrimmedLinksRecords(): Observable<ITrimmedLinksData[] | null> {
    return this.trimmedLinks.asObservable();
  }

  deleteTrimmedLinkById(trimUrlId: number): void {
    const updatedRecords = this.trimmedLinks.value?.filter((record) => record.trimUrlId !== trimUrlId) || [];
    this.trimmedLinks.next(updatedRecords);
  }

  setLinkToEdit(linkData: ITrimmedLinksData) {
    this.linkToEdit.next(linkData);
  }

  getLinkToEdit(): Observable<ITrimmedLinksData | null> {
    return this.linkToEdit.asObservable();
  }

  addTrimmedLink(trimmedLink: ITrimmedLinksData) {
    this.trimmedLinks.value?.unshift(trimmedLink);
  }
}
