import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LinkComponent } from './pages/link/link.component';

export const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'trim/home', component: HomeComponent },
  { path: 'trim/link/:id', component: LinkComponent },
  { path: '**', redirectTo: 'trim/home' },
];
