<form [formGroup]="formGroup" class="flex justify-content-between align-items-center table-options__container">
  <div class="flex gap-2">
    <p-dropdown
      [options]="trimTypeDropdownOptions"
      formControlName="selectedOption"
      optionLabel="name"
    >
      <ng-template let-option pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div class="flex gap-2">
            <p-radioButton
              [value]="option" />
            <span>
              {{ option.name }}
            </span>
          </div>
        </div>
      </ng-template>
    </p-dropdown>

    <p-calendar
      formControlName="date"
      placeholder="Choose date"
      [showIcon]="true"
      [iconDisplay]="'input'"
      selectionMode="range"
    />
  </div>
  <div class="flex gap-2 align-items-center">
    <p-iconField iconPosition="left">
      <fa-icon [icon]="faSearch" [size]="'sm'" class="search-icon" />
      <input type="text" pInputText placeholder="Search Alias"
             formControlName="searchInput"
      />
    </p-iconField>
    <p-button
      o-button
      label="Export CSV"
      (click)="exportLinksAsCsv()"
      [loading]="exportLoading"
      [disabled]="exportLoading"
      [outlined]="true"
      class="font-semibold"
    />
  </div>
</form>
