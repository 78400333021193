import { Component, Input } from '@angular/core';
import { ISocialListItemModel } from '../../../models';
import { DataViewModule } from 'primeng/dataview';

@Component({
  selector: 'app-social-list-view',
  standalone: true,
  imports: [DataViewModule],
  templateUrl: './social-list-view.component.html',
  styleUrl: './social-list-view.component.scss',
})
export class SocialListViewComponent {
  @Input() socialsData!: ISocialListItemModel[];
}
