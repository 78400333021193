export const CSocialMediaData = [
  {
    name: 'Whatsapp',
    icon: 'pi-whatsapp',
  },
  {
    name: 'Facebook',
    icon: 'pi-facebook',
  },
  {
    name: 'Instagram',
    icon: 'pi-instagram',
  },
  {
    name: 'X',
    icon: 'pi-twitter',
  },
  {
    name: 'Threads',
    icon: 'pi-at',
  },
  {
    name: 'Email',
    icon: 'pi-envelope',
  },
];
