<p-dialog [modal]="true" (onHide)="closeDialog()" [(visible)]="visible" [draggable]="false" [dismissableMask]="true">
  <div class="flex flex-column align-items-center justify-content-center bulk-dialog">
    <p class="mb-2 mt-0 text-center text-3xl font-bold w-full bulk-dialog__title">Bulk file {{ fileInfo?.fileName }}</p>

    <div class="bulk-dialog__action-bar w-full flex flex-row align-items-center justify-content-between mt-5 mb-4">
      <p-iconField iconPosition="left">
        <fa-icon [icon]="faSearch" [size]="'sm'" class="search-icon" />
        <input type="text" pInputText placeholder="Search URLs" [formControl]="searchControl" />
      </p-iconField>

      <div class="flex align-items-center">
        <span
          (click)="deleteFile()"
          class="bulk-dialog__action-bar-delete-btn border-round-2xl cursor-pointer border-solid border-1 w-3rem h-3rem flex align-items-center justify-content-center"
        >
          @if (!deleteLoading) {
            <img [src]="assetUrl('icons/delete-icon.svg')" width="16" height="19" alt="delete" />
          } @else {
            <p-progressSpinner ariaLabel="loading" styleClass="export-loading" />
          }
        </span>
        <span
          (click)="downloadFile()"
          class="bulk-dialog__action-bar-download-btn border-round-2xl cursor-pointer ml-2 border-solid border-1 w-3rem h-3rem flex align-items-center justify-content-center"
        >
          @if (!exportLoading) {
            <img [src]="assetUrl('icons/download-icon.svg')" width="19" height="19" alt="download" />
          } @else {
            <p-progressSpinner ariaLabel="loading" styleClass="export-loading" />
          }
        </span>
      </div>
    </div>

    @if (fileTrimmedLinksData.length > 0 || isLinksFileDataLoading()) {
      <p-table [value]="fileTrimmedLinksData" styleClass="p-datatable-gridlines p-datatable-sm" class="w-full">
        <ng-template pTemplate="header">
          <tr>
            <th>URLs</th>
            <th>Alias</th>
            <th>Clicks</th>
            <th>Actions</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-url>
          @if (!isLinksFileDataLoading()) {
            <tr>
              <td class="col-6">
                <div class="flex flex-column">
                  <a
                    class="text-primary font-semibold text-lg my-0 no-underline"
                    target="_blank"
                    [href]="url.trimmedUrl"
                  >
                    {{ url.trimmedUrl }}
                  </a>
                  <p
                    [autoHide]="false"
                    [pTooltip]="url.destinationUrl"
                    tooltipPosition="top"
                    class="my-0 text-base mt-2 white-space-nowrap overflow-hidden text-overflow-ellipsis text-ellipsis table-custom-text"
                  >
                    {{ url.destinationUrl }}
                  </p>
                </div>
              </td>
              <td class="col-2">{{ url.aliasName }}</td>
              <td class="col-2">{{ url.clickCount }}</td>
              <td class="col-2">
                <div class="flex gap-4 icon-colors">
                  <img [src]="assetUrl('icons/analytic.svg')" (click)="handleShowAnalytics(url)" />
                  <img [src]="assetUrl('icons/share.svg')" pTooltip="Coming soon!" tooltipPosition="top" />
                  <img [src]="assetUrl('icons/qr-code.svg')" pTooltip="Coming soon!" tooltipPosition="top" />
                  <div #contextTarget class="cursor-pointer" (click)="setTargetUrl(url)">
                    <fa-icon [icon]="faEllipsis" [size]="'lg'" />
                  </div>
                  <p-contextMenu
                    [target]="contextTarget"
                    [model]="contextMenuItems"
                    [appendTo]="'body'"
                    triggerEvent="click"
                  />
                </div>
              </td>
            </tr>
          } @else {
            <tr>
              <td class="col-6"><p-skeleton height="2rem" /></td>
              <td class="col-2"><p-skeleton height="2rem" /></td>
              <td class="col-2"><p-skeleton height="2rem" /></td>
              <td class="col-2"><p-skeleton height="2rem" /></td>
            </tr>
          }
        </ng-template>
      </p-table>
    } @else {
      <div
        class="w-full h-28rem flex flex-column gap-4 justify-content-center align-items-center file-table--empty-state"
      >
        <img [src]="assetUrl('icons/empty-table.svg')" />
        <h2 class="my-0">No Files to show</h2>
        <p class="font-medium text-center">
          Here, you can view all the trimmed URLs created from files, along with options
          <br />
          to manage and track each link by the number of clicks received.
        </p>
      </div>
    }
  </div>
</p-dialog>
