<o-confirm-modal
  [(visible)]="isShowConfirmModal"
  [data]="deleteFileConfirmationData"
  [modalIcon]="environment.publicPath + '/assets/icons/t-alert.svg'"
  (acceptConfirmation)="deleteFile()"
  (rejectConfirmation)="closeConfirmDialog()"
></o-confirm-modal>

@if (filesData().length || isLinksDataLoading()) {
  <div class="file-table__container">
    <p-table [value]="filesData()" styleClass=" p-datatable-sm w-full">
      <ng-template pTemplate="header">
        <tr>
          <th class="tabel-header-text">URLs</th>
          <th class="tabel-header-text">Clicks</th>
          <th class="tabel-header-text">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-file>
        @if (!isLinksDataLoading()) {
          <tr>
            <td class="col-6">
              <div class="flex gap-2 pl-2">
                <fa-icon [icon]="faFile" [size]="'lg'" />
                <p class="font-semibold my-0">{{ file.fileName }}</p>
              </div>
            </td>
            <td class="col-2">
              <p>{{ file.noOfLinks }}</p>
            </td>
            <td class="col-2">
              <div class="flex gap-4 icon-colors">
                <div (click)="setSelectedItem(file)" class="cursor-pointer">
                  <fa-icon [icon]="faQrcode" [size]="'lg'" />
                </div>
                <div #contextTarget class="cursor-pointer" (click)="setTargetUrl(file)">
                  <fa-icon [icon]="faEllipsis" [size]="'lg'" />
                </div>
                <p-contextMenu
                  [target]="contextTarget"
                  [model]="contextMenuItems"
                  [appendTo]="'body'"
                  triggerEvent="click"
                />
              </div>
            </td>
          </tr>
        } @else {
          <tr>
            <td class="col-6"><p-skeleton height="2rem" /></td>
            <td class="col-2"><p-skeleton height="2rem" /></td>
            <td class="col-2"><p-skeleton height="2rem" /></td>
          </tr>
        }
      </ng-template>
    </p-table>
    <app-file-links-dialog [(visible)]="isShowFileModal" [(fileInfo)]="fileInfo" />
  </div>
} @else {
  <div class="w-full h-28rem flex flex-column gap-4 justify-content-center align-items-center file-table--empty-state">
    <img [src]="assetUrl('icons/empty-table.svg')" />
    <h2 class="my-0">No Files to show</h2>
    <p class="font-medium text-center">
      Here, you can view all the trimmed URLs created from files, along with options
      <br />
      to manage and track each link by the number of clicks received.
    </p>
  </div>
}
