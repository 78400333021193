import { faCrown, faLink, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { IAnalyticCard, ITrimDropdownOptions } from '../../models';

export const TRIM_DROPDOWN_OPTIONS: ITrimDropdownOptions = {
  ALL_LINK: { name: 'All links', value: 'ALL_LINKS' },
  FILES_ONLY: { name: 'Files only', value: 'FILES_ONLY' },
};

export const ANALYTIC_CARDS_DATA: IAnalyticCard[] = [
  {
    key: 'totalLinks',
    header: 'Short URLs',
    data: 0,
    icon: faLink,
    background: 'linear-gradient(180deg, rgba(224, 255, 237, 1) 0%, rgba(245, 255, 176, 0.67) 100%)',
    iconColor: '#1A384B',
  },
  {
    key: 'totalClicks',
    header: 'Total clicks',
    data: 0,
    icon: faLink,
    background: 'linear-gradient(180deg, rgba(224, 250, 255, 1) 0%, rgba(176, 230, 255, 0.67) 100%)',
    iconColor: '#00ACFB',
  },
  {
    key: 'uniqueClicks',
    header: 'Unique clicks',
    data: 0,
    icon: faCrown,
    background: 'linear-gradient(180deg, rgba(224, 232, 255, 1) 0%, rgba(177, 176, 255, 0.67) 100%)',
    iconColor: '#2F20C6',
  },
  {
    key: 'brokenLinks',
    header: 'Broken links',
    data: 0,
    icon: faLink,
    background: 'linear-gradient(180deg, rgba(255, 224, 224, 1) 0%, rgba(255, 176, 176, 0.67) 100%)',
    iconColor: '#B30000',
  },
  {
    key: 'qrCount',
    header: 'QR accessed',
    data: 0,
    icon: faQrcode,
    background: 'linear-gradient(180deg, rgba(233, 224, 255, 1) 0%, rgba(240, 176, 255, 0.67) 100%)',
    iconColor: '#7E11BA',
  },
];
