import { inject, Injectable } from '@angular/core';
import { OtrimState } from './otrim.state';
import { OtrimApi } from './otrim.api';
import { Observable } from 'rxjs';
import {
  IFileData,
  IFileLinksPayload,
  IFilesPayload,
  IFileTrimmedLinksData,
  IApiResponse,
  IApiResponseWithData,
  ITrimmedLinksData,
  IRangeDatePayloads,
  ITrimSingleLink,
  IDashboard,
  IDashboardPayload,
  IBulkTemplatePayload,
  ISearchAliasPayload,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class OtrimFacade {
  private otrimApi = inject(OtrimApi);
  private otrimState = inject(OtrimState);

  trimSingleLink(payload: ITrimSingleLink): Observable<IApiResponseWithData<ITrimmedLinksData>> {
    return this.otrimApi.insertCustomTrim(payload);
  }

  getDashboardData(payload: IDashboardPayload): Observable<IApiResponse<IDashboard>> {
    return this.otrimApi.getDashboardData(payload);
  }

  setDashboardStatistics(stat: IDashboard): void {
    this.otrimState.setDashboardStatistics(stat);
  }

  getDashboardStatistics(): Observable<IDashboard | null> {
    return this.otrimState.getDashboardStatistics();
  }

  getTrimmedLinks(payload: IRangeDatePayloads): Observable<IApiResponseWithData<ITrimmedLinksData[]>> {
    return this.otrimApi.getTrimAnalytics(payload);
  }

  getAllFilesData(payload: IFilesPayload): Observable<IApiResponseWithData<IFileData[]>> {
    return this.otrimApi.getAllFilesData(payload);
  }

  getFileLinks(payload: IFileLinksPayload): Observable<IApiResponseWithData<IFileTrimmedLinksData[]>> {
    return this.otrimApi.fetchRecordByFile(payload);
  }

  getSampleCsv(payload: IBulkTemplatePayload): Observable<Blob> {
    return this.otrimApi.getSampleCsv(payload);
  }

  fileUploader(url: any, params: any): Observable<IApiResponseWithData<string>> {
    return this.otrimApi.checkFileName(url, params);
  }

  downloadLinksAsCsv(payload: IRangeDatePayloads) {
    return this.otrimApi.getTrimUrlsCsv(payload);
  }

  exportFileAsCsv(payload: string) {
    return this.otrimApi.exportFileAsCsv(payload);
  }

  searchAlias(payload: ISearchAliasPayload): Observable<IApiResponseWithData<ITrimmedLinksData[]>> {
    return this.otrimApi.searchAlias(payload);
  }

  setFilteredLinks(data: ITrimmedLinksData[]): void {
    this.otrimState.setFilteredLinks(data);
  }

  getFilteredLinks(): Observable<ITrimmedLinksData[] | null> {
    return this.otrimState.getFilteredLinks();
  }

  deleteFile(fileId: string): Observable<IApiResponseWithData<string>> {
    return this.otrimApi.deleteFile(fileId);
  }

  setFilesRecords(data: IFileData[]) {
    this.otrimState.setFilesRecords(data);
  }

  getFilesRecords(): Observable<IFileData[] | null> {
    return this.otrimState.getFilesRecords();
  }

  deleteFileRecord(fileId: number): void {
    this.otrimState.deleteFileRecord(fileId);
  }

  setDateRange(data: IRangeDatePayloads) {
    this.otrimState.setDateRange(data);
  }

  getDateRange(): Observable<IRangeDatePayloads> {
    return this.otrimState.getDateRange();
  }

  deleteTrimmedLink(payload: string) {
    return this.otrimApi.deleteTrimmedLink(payload);
  }

  setTrimmedLinks(data: ITrimmedLinksData[]) {
    this.otrimState.setTrimmedLinks(data);
  }

  getTrimmedLinksRecords(): Observable<ITrimmedLinksData[] | null> {
    return this.otrimState.getTrimmedLinksRecords();
  }

  deleteTrimmedLinkById(trimUrlId: number): void {
    this.otrimState.deleteTrimmedLinkById(trimUrlId);
  }

  setLinkToEdit(linkData: ITrimmedLinksData) {
    this.otrimState.setLinkToEdit(linkData);
  }

  getLinkToEdit(): Observable<ITrimmedLinksData | null> {
    return this.otrimState.getLinkToEdit();
  }

  UpdatedTrimmedLink(payload: any) {
    return this.otrimApi.UpdatedTrimmedLink(payload);
  }

  addTrimmedLink(trimmedLink: ITrimmedLinksData) {
    this.otrimState.addTrimmedLink(trimmedLink);
  }
}
