import { IFileData, IFileTrimmedLinksData } from '../../models';
import { IConfirmDialogData } from 'o-suite-lib';

export const loadingData: IFileData = {
  date: '',
  fileId: 0,
  fileName: '',
  isCustom: false,
  noOfLinks: 0,
};

export const fileLinksLoadingData: IFileTrimmedLinksData = {
  aliasName: '',
  customName: '',
  targetUrl: '',
  trimmedUrl: '',
  trimUrlId: 0
};

export const deleteFileConfirmationData: IConfirmDialogData = {
  header: 'Delete URL',
  message: 'Do you want to proceed with deleting this File?',
  acceptBtnLabel: 'Delete',
  rejectBtnLabel: 'Cancel',
};
